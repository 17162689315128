import React, {Component} from 'react';
import './css/aboutStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Partners from './partners.js';
import {getMediumPosts} from '../actions/postActions';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import ScrollableAnchor from 'react-scrollable-anchor';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

class About extends Component {
   
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    this.props.getMediumPosts('dasdasd');
  }
  render() {
    var mediumPosts;
    var mediumTitles
    if(this.props.medium){
     mediumPosts = this.props.medium;
     mediumTitles = this.props.title
    }
    else {
    mediumPosts = 'empty';
    mediumTitles = 'empty';
  }
      return (
        <ScrollableAnchor id={'about'}>
        <section id="aboutt">
        <Partners />
        <div id="aboutInner">
        <div id="aboutCards">
        <div id="aboutCardsL">
        <a href="https://medium.com/@b.programming/building-and-maintaining-an-audience-e909e1fb79c8" target="_blank" rel="noopener noreferrer" >
        <div className="leftCards">
        <h2 className="mediumTitle">{mediumTitles[1].title}</h2>
        <div id="livingOnTHeEdge"dangerouslySetInnerHTML={{ __html: mediumPosts[1]}} />
        </div>
        </a>
        <a href="https://medium.com/@b.programming/the-best-way-to-start-a-web-development-project-ac53c1967b9b" target="_blank" rel="noopener noreferrer" >        
        <div className="leftCards">
        <h2 className="mediumTitle">{mediumTitles[2].title}</h2>
        <div id="livingOnTHeEdge"dangerouslySetInnerHTML={{ __html: mediumPosts[2]}} />
        </div>
        </a>
        </div>
        <div id="aboutCardsR">
        <a href="https://medium.com/@b.programming/why-web-applications-are-the-future-4b85c12223c7" target="_blank" rel="noopener noreferrer" >
        <div className="rightCards">
        <h2 className="mediumTitle">{mediumTitles[3].title}</h2>
        <div id="livingOnTHeEdge"dangerouslySetInnerHTML={{ __html: mediumPosts[3]}} />
        </div>
        </a>
        <a href="https://medium.com/@b.programming/why-web-applications-are-the-future-d4787ba5f446" target="_blank" rel="noopener noreferrer">
        <div className="rightCards">
        <h2 className="mediumTitle">{mediumTitles[4].title}</h2>
        <div id="livingOnTHeEdge"dangerouslySetInnerHTML={{ __html: mediumPosts[4]}} />
        </div>
        </a>
        </div>
        </div>
        <div id="aboutText">
        <div id="innerAboutText">
        <h2 className="mediumTitle">About us</h2>
        <div id="livingOnTHeEdge">
          What started as a simple calling to improve website quality and performance as well as its reach in the local market, quickly outgrew its initial calling...
        </div>
        <a href="/about"> <div id="Aboutdetails">
          <Translate content="aboutInner"/> <FontAwesomeIcon icon={faArrowRight} size="lg"/>
        </div>
         </a>
        </div>
        </div>
        </div>
        </section>
        </ScrollableAnchor>
      )
    }
  }

  let check = {
    ifMediumText: function(state) {
     if (state.posts.medium.length !== 0 && state.posts.medium[0].title) { 
     var mediumPosts = []
    Array.from(state.posts.medium).forEach(function (child) { 
       var a = child.description;
       var regEx = a.match(/<p>(.*?)<\/p>/);
       var sliced = regEx[1].slice(0, 115) + "...";
       mediumPosts.push(sliced);
  })
  return mediumPosts
} 
    else return null  
    },
    ifMediumTitle: function(state) {
      var mediumPosts = []
      Array.from(state.posts.medium).forEach(function (child) { 
         var a = child.title;
         mediumPosts.push(a);
    })
    return mediumPosts
}
  }

About.propTypes = {
  getMediumPosts: PropTypes.func.isRequired,
  medium: PropTypes.array,
  title: PropTypes.array.isRequired
  };
  
  const mapStateToProps = state => ({
    medium: check.ifMediumText(state),
    title: state.posts.medium
  });


export default connect(mapStateToProps, {getMediumPosts})(About);
