export default {
  header: {
    home: "HOME",
    whatWeDo:"WHAT WE DO",
    aboutUs:"ABOUT US",
    testemonials:"TESTEMONIALS",
    contact:"CONTACT"
   },
  home: {
    title: 'Imagine...',
    subtext: 'automating your business with a single web application',
    heroButton: 'Learn more'
  },
  services: {
    title: 'What we do',
    subtitle1:'Web application development services',
    storitev1:'From cloud based financial applications to large scale e-commerce platforms you can count on us to deliver the highest quality services on the market',
    subtitle2:'Custom website development services',
    storitev2:"If you're looking to impress your clients and outperform your rivals then let us make sure you'll keep expanding your market share",
    subtitle3:'Data visualization & analytics tools integration',
    storitev3:"Data now being world's most valuable asset means having a way to collect, sort and present it is crucial in sustainably growing your business",
    subtitle4:'Web design and online branding services',
    storitev4:'From brand identities to web design we help you tailor your brand to your selected audience and prepare it to withstand scalability issues',
    subtitle5:'Social media marketing', 
    storitev5:'we use your analytics data to target, build and maintain a specific demographic audience across relevant social media platforms',
    subtitle6:'Conversion rates optimization services',
    storitev6:'Achieve better conversion rates by implementing carefully designed sales funnels that guide your clients through your website or web application',
    reasons: {
     title:"Why choose our services",
     reason1:"Quality is our first priority",
     reason2:"We use the latest technology",
     reason3:"We employ the top talent",
     reason4:"We have strong international support",
     reason5:"We focus on the heavy lifting",
     reason6:"We produce the best results",
     reason7:"We optimize your profit margins",
     reason8:"We offer free consulting"
    }
},
interestedServices:{
  interested: "Let us help you grow your business",
  button: "Get in touch"
},
partners: "Our partners",
aboutInner: 'More details',
testemonials: {
    title: 'Our Happy Customers',
    firstText: 'The best price to value ratio',
    firstClient: 'CEO of Stepinside',
    secondText: 'Drastically improved performance',
    secondClient: 'Co-owner of Jazsemvredu',
    thirdText: 'Our new website looks great',
    thirdClient: 'CEO of Alphacrypt',
    fourthText: 'Quick to implement all changes',
    fourthClient: 'Owner of Nikolajferdinand'
  },
  contact:{
  title:"Talk with us",
  first:"Name",
  second:"Email",
  third:"How can we help you?",
  button:"Send your inquiry",
  address:"Address",
  phone:"Phone",
  email:"Email",
  consultation: "Request free consultation",
  button2:"Submit"
  },
  footer: {
    coppyright:"coppyright @ 2019 B.P. d.o.o. All Rights Reserved",
    cookies: 'Cookies',
    privacyPolicy: "Privacy policy",
    terms: "Terms of service",
    language:"Language:"
  },
inquiry:{
  contact: {
    title1: "We're Excited to Connect ",
    title2: "and Elevate Your Online Presence!",
  }
}
}
