import React from 'react';
import Hero  from './css/hero.png'
import './css/home.css'
import ScrollableAnchor from 'react-scrollable-anchor';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

const Home = () => {
  const bg = {
    backgroundImage: `url(${Hero})`
  };
return (
  <ScrollableAnchor id={'home'}>
<section id="homee">
  <div style={bg} id="heroPhoto">
      <div id="heroText">
    <div id="innerHeroText">
      <div id="centeredInnerHeroText">
      <Translate content="home.title" component="h2"/>
      <Translate content="home.subtext"/><br />
    <a href="#contact"><button id="getInTouch" className="hButton">
    <Translate content="home.heroButton" /></button></a>
      </div>
    </div>
  </div>
    </div>
</section>
</ScrollableAnchor>
);
};
export default Home
