export default {
  header: {
   home: "DOMOV",
   whatWeDo:"STORITVE",
   aboutUs:"O NAS",
   testemonials:"MNENJA",
   contact:"KONTAKT"
  },
  home: {
    title: 'Predstavljajte si...',
    subtext: 'avtomacijo vseh vaših dolgotrajnih delovnih procesov',
    heroButton: 'Learn more'
  },
  services: {
    title: 'Naše storitve',
    subtitle1:'Načrtovanje in razvoj namenskih spletnih aplikacij',
    storitev1:'Od zasebnih finančnih applikacij v oblaku do večjih spletnih trgovin se lahko vedno zanesete na nas za najbolj kvalitetne programske rešitve na trgu',
    subtitle2:'Izdelava namenskih spletnih strani',
    storitev2:"Če želite pustiti vtis na strankah in premagati konkurenco nam dovoljite da vam zagotovimo dologotrajno rast vašega tržnega deleža",
    subtitle3:'Integracija analiticnih orodij&podatkovnih vizualizacij',
    storitev3:"Dejstvo da so podatki zdaj najdražja dobrina naredi njihovo shranjevanje, sortiranje in prezentacijo neprecenljivo za trajnostno rast vašega podjetja",
    subtitle4:'Spletno oblikovanje&oblikovanje blagovne znamke',
    storitev4:'Od oblikovanja blagovne znamke do spletnega oblikovanja vam pomagamo prilagoditi znamko izbrani publiki in jo pripravimo za širjenje tržnega deleža',
    subtitle5:'Trženje na socialnih omrežjih' 
/*     socialna omrežja&marketing
    marketing za socialna omrežja */ , 
    storitev5:'Osredotočimo se na analitiko vašega spletnega mesta ter z njo dolgotrajno zgradimo in zadržimo vašo čiljno publiko na vseh ključnih socialnih omrežjih',
    subtitle6:'Optimizacija prodajnih kanalov',
    storitev6:'Pridobite še večjo učinkovitost vašega spletnega mesta z uporabo analitičnih podatkov v vaših prodajnih kanalih in vodite stranko po optimalni poti nakupa',
    reasons: {
     title:"Zakaj smo prava izbira",
     reason1:"Kvaliteta je naša glavna prioriteta",
     reason2:"Uporabljamo najnaprednejšo tehnologijo",
     reason3:"Našo ekipo sestavljajo /samo/ specializirani strokovnjaki",
     reason4:"Nudimo močno internacionalno podporo ??",
     reason5:"Specializirani smo za kompleksne projekte",
     reason6:"Proizvedemo najboljše rezultate",
     reason7:"Optimiziramo vaše profitne marže",
     reason8:"Ponujamo brezplačne konzultacije"
    }
},
interestedServices:{
  interested: "Pustite da vam mi pomagamo razširiti podjetje",
  button: "Kontakt"
},
partners: "Naši partnerji",
aboutInner: 'Več informacij',
testemonials: {
  title: 'Our Happy Customers',
  firstText: 'The best price to value ratio',
  firstClient: 'CEO of Stepinside',
  secondText: 'Drastically improved performance',
  secondClient: 'Co-owner of Jazsemvredu',
  thirdText: 'Our new website looks great',
  thirdClient: 'CEO of Alphacrypt',
  fourthText: 'Quick to implement all changes',
  fourthClient: 'Owner of Nikolajferdinand'
  },
  contact:{
  title:"Pošljite povpraševanje",
  first:"Name",
  second:"Email",
  third:"How can we help you?",
  button:"Pošlji povpraševanje",
  address:"Naslov",
  phone:"GSM",
  email:"Elektronska pošta",
  consultation: "Naročite brezplačno konzultacijo",
  button2:"Pridobi termin"
  },
  footer: {
    coppyright:"Vse pravice pridržane @2019 B.P. d.o.o.",
    cookies: 'Piškotki',
    privacyPolicy: "Pravilnik o zasebnosti",
    terms: "Pogoji sodelovanja",
    language:"Jezik:"
  },
  inquiry:{
    contact: {
      title1: "Veselimo se vašega povpraševanja",
      title2: "in izboljšanja vaše spletne prisotnosti!"
    }
  }
}
