import React from 'react';
import './components/css/aboutUs.css'
import Header from './components/header.js'
import Footer from './components/footer.js'
import angBanner  from './components/css/bannerEng.png'
import sloBanner  from './components/css/bannerSlo.png'
import InquiryPostForm from './components/inquiryPostForm.js';
import hero  from './components/css/hero.png';
import counterpart from 'counterpart';
import en from './components/translations/en'
import slo from './components/translations/slo'


counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: 'slo',
    }
  }
  


onLangChange = (e) => {
  this.setState({lang: e.target.value});
  counterpart.setLocale(e.target.value);
  console.log(counterpart.getLocale(), "Locc");
 }

  render() {
    const heroBg = {
      backgroundImage: `url(${hero})`,
      minHeight: '60vh',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    };
    const bg = {
      backgroundImage: `url(${angBanner})`,
      backgroundColor: 'white',
      backgroundSize: 'contain'
    };
    const bgSlo = {
      backgroundImage: `url(${sloBanner})`,
      backgroundColor: 'white',
      backgroundSize: 'contain'
    };
    return (
      <div className="Inquiry">
          <Header lang={this.state.lang} onLangChange={this.onLangChange}/>
          {/* {console.log(counterpart(), "lang")} */}
          <div id="InquiryText">
            <div id="innerInquiryTextContaier" style={heroBg}>
            <div id="InquiryTextTitle">
                <h2 className="inquiryTextTitleInner">Popeljite svojo spletno stran na višjo raven že danes!</h2>
             </div>
             <div id="InquiryTextt">
            <h2>Welcome to B.Programming, the #1 choice for businesses ready to dominate the online world! 🚀 <br /><br />
            Our experienced team crafts stunning, high-performance websites designed to attract your ideal customers and boost your revenue. <br /><br />
            Don't miss this chance to elevate your brand and stand out in the digital crowd!</h2>
             </div>
            </div>
             {this.state.lang=="slo"?
             <div id="InquiryImage" style={bgSlo} className={(this.state.lang=="slo"? "InquiryImageSlo" : "InquiryImageEn")}>{console.log(typeof(counterpart.getLocale()), "type")}</div>
             :
              <div id="InquiryImage" style={bg} className={(this.state.lang=="slo"? "InquiryImageSlo" : "InquiryImageEn")}>{console.log(counterpart.getLocale()=="slo", counterpart.getLocale(), "type", this.state.lang)}</div>
             }
{/*   */}
             <InquiryPostForm lang={this.state.lang} onLangChange={this.onLangChange}/> 
          </div>
          <Footer lang={this.state.lang} onLangChange={this.onLangChange} />
      </div>
    );
  }
}

export default App;