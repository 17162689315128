import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {login} from '../actions/postActions.js';
import './css/form.css'
import MapContainer from './mapComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from'@fortawesome/free-regular-svg-icons'
import ScrollableAnchor from 'react-scrollable-anchor';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en.js'
import slo from './translations/slo.js'
import emailjs from '@emailjs/browser';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');




class inquiryPostForm extends Component {
constructor(props){
  super(props);
  this.formRef = React.createRef()
  this.state = {
     name: 'Name',
     //password: 'Email',
     clientText: 'How can we help you?',
     v: 0,
     email:'Email',
     phone: 'Phone Number',
     coupon: 'Discount code',
     firstInput: 'Name',
     secondInput:'Email',
     thirdInput: 'Phone Number',
     fourthInput: 'How can we help you?',
     fifthInput: 'Discount code'
    };

  this.onChange = this.onChange.bind(this);
  //this.onSubmit = this.onSubmit.bind(this);
  this.focus = this.focus.bind(this);
  this.blur = this.blur.bind(this);
  this.sendEmail = this.sendEmail.bind(this);

}

sendEmail(e){
  e.preventDefault();
  console.log("2222", this.formRef.current);
  console.log(process.env.REACT_APP_TITLE);
  emailjs
    .sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      this.formRef.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (result) => {
        alert('message sent successfully...');
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
};
onChange(e) {
  this.setState({ [e.target.name]: e.target.value });
}
focus(e){
    if(e.target.value === this.state.firstInput || e.target.value === this.state.secondInput || e.target.value === this.state.thirdInput || e.target.value === this.state.fourthInput || e.target.value === this.state.fifthInput){
    e.target.value = '';  
    }
    if(e.target.rows){
      e.target.rows = "5";
    }
}
blur(e, inpt){
  if(!e.target.value) e.target.value = inpt;
 if(e.target.rows) e.target.rows = "1";
}
// onSubmit(e) {
//  e.preventDefault();
//  var sequence = this.state.v + 1;
//  this.setState({v: sequence});
//  if(this.state.clientText !== 'How can we help you?'){
//   var post = {
//     name: this.state.name,
//     text: this.state.clientText,
//     email: this.state.email,
//     v: this.state.v
//   }
// }
// if(this.state.clientText === 'How can we help you?') {
//  var post = {
//    name: this.state.name,
//    text: '',
//    email: this.state.email,
//    v: this.state.v
//  }}
// this.props.login(post);
// alert( "Success,\nWe will get back to your shortly");
// }

  render() {
    return (
      <ScrollableAnchor id={'contact'}>
      <section id="form">
        <div className="inquiryFormHeader"><h2>Ready to start? Let's create something amazing together!</h2></div>
      <div id="input-form">
        <div className="inquiryContactInnerHeader">
        <Translate content="inquiry.contact.title1" component="h3"/>
        <Translate content="inquiry.contact.title2" component="h3"/>
        </div>
      {/*  //this.onsubmit */}
        <form onSubmit={this.sendEmail} ref={this.formRef}>
          <div className="stylish">
            <input type="text" name="name" isrequired onFocus={this.focus} onBlur={ e => this.blur(e, this.state.firstInput)} onChange={this.onChange} value={this.state.name}/>
          </div>
          <div className="stylish">
            <input type="email" name="email" isrequired onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.secondInput)} onChange={this.onChange} value={this.state.email}/>
          </div>
          <div className="stylish">
            <input type="tel" name="phone" isrequired onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.thirdInput)} onChange={this.onChange} value={this.state.phone}/>
          </div>
          <div className="stylish">
            <textarea rows = "1" type="text" name="clientText" onFocus={this.focus} onBlur={ e => this.blur(e, this.state.fourthInput)} onChange={this.onChange} value={this.state.clientText}/>
          </div>
          <div className="stylish">
            <input type="text" name="coupon" isrequired onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.fifthInput)} onChange={this.onChange} value={this.state.coupon}/>
          </div>
          <br />
          <button type="submit"><Translate content="contact.button"/></button>
        </form>
        <div id="SocialForm">
        <div className="SocialFormContent">

        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faMapMarkerAlt} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size="lg"/>
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.address" component="h3"/>
        <p>Ljubljana, SI</p></div>
        </div>
        <div className="SocialFormContent">
        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faPhoneAlt} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size='lg' />
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.phone" component="h3"/>
        <p>+386 51 621 161</p></div>
        </div>
        <div className="SocialFormContent">
        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faEnvelope} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size='lg' />
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.email" component="h3"/>
        <p>info@bprogramming.com</p></div>
        </div>
        </div>
        </div>
        <div className="map">
      <MapContainer />
      </div>
        </section>
        </ScrollableAnchor>
    )
  }
}
inquiryPostForm.propTypes = {
login: PropTypes.func.isRequired
};

export default connect(null, {login})(inquiryPostForm);