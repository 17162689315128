import React from 'react'
import './css/headerStyle.scss'
import Logo from './media/bp_logo_znak-10.svg'
import { configureAnchors } from 'react-scrollable-anchor'
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');

class Header extends React.Component {

 constructor(props){
   super(props);
   this.state = {
     errorOccurred: 'false'
   };
 }
  componentDidMount(){

    // Definition of caller element
    var getTriggerElement = function(el) {
      var isCollapse = el.getAttribute('data-collapse');
      if (isCollapse !== null) {
        return el;
      } else {
        var isParentCollapse = el.parentNode.getAttribute('data-collapse');
        
        if(isParentCollapse === undefined || isParentCollapse === null || !isParentCollapse){
          
        } 
        var x = isParentCollapse;
        return (isParentCollapse !== null) ? el.parentNode : 'undefined';
      }
    };
  
    // A handler for click on toggle button
    var collapseClickHandler = function(event) {
      var triggerEl = getTriggerElement(event.target);
      // If trigger element does not exist
      if (triggerEl === 'undefined') {
        return false;
      }
  
      // If the target element exists
      var targetEl = document.querySelector(triggerEl.getAttribute('data-target'));
      if (targetEl) {
        triggerEl.classList.toggle('-active');
        targetEl.classList.toggle('-on');
      }
    };
  
    // Delegated event
    document.addEventListener('click', collapseClickHandler, false);
   
    configureAnchors({offset: -68, scrollDuration: 400})

  }

  render() {
    return (
    <div className="navbar-component">
      
      <div className="navbar area">

        <a href="/#home" className="brand">
        <div id="headerLogo"><img id="headerLogoImg"src={Logo} alt="Backend Programming" viewBox="0 0 0 0" width="43.25" height="25.5" /></div>
        <div id="headerText"><h2>b.programming</h2></div>
        
        </a>

        <nav role="navigation" id="navigation" className="list">
          <a href="/#home" className="item -link"><Translate content="header.home"/></a>
          <a href="/#services" className="item -link"><Translate content="header.whatWeDo"/></a>
          <a href="/#about" className="item -link"><Translate content="header.aboutUs"/></a>
          <a href="/#testemonials" className="item -link"><Translate content="header.testemonials"/></a>
          <a href="/#contact" className="item -link"><Translate content="header.contact"/></a>
          <a href="tel:+38651719018" className="item -link">
          <span className="headerNumber">+386 51 719 018</span></a>
        </nav>
    
        <button data-collapse data-target="#navigation" className="toggle">
          
          <span className="icon"></span>
          
        </button>
        
      </div> 
    </div>

    );
  }
}

export default Header;
