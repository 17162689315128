import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {login} from '../actions/postActions';
import './css/form.css'
import MapContainer from './mapComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from'@fortawesome/free-regular-svg-icons'
import ScrollableAnchor from 'react-scrollable-anchor';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './translations/en'
import slo from './translations/slo'

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('slo', slo);
counterpart.setLocale('en');


class postForm extends Component {
constructor(props){
  super(props);
  this.state = {
     name: 'Name',
     //password: 'Email',
     clientText: 'How can we help you?',
     v: 0,
     email:'Email',
     firstInput: 'Name',
     secondInput:'Email',
     thirdInput: 'How can we help you?'
  
    };

  this.onChange = this.onChange.bind(this);
  this.onSubmit = this.onSubmit.bind(this);
  this.focus = this.focus.bind(this);
  this.blur = this.blur.bind(this);
}
onChange(e) {
  this.setState({ [e.target.name]: e.target.value });
}
focus(e){
    if(e.target.value === this.state.firstInput || e.target.value === this.state.secondInput || e.target.value === this.state.thirdInput){
    e.target.value = '';  
    }
    if(e.target.rows){
      e.target.rows = "3";
    }
}
blur(e, inpt){
  if(!e.target.value) e.target.value = inpt;
 if(e.target.rows) e.target.rows = "1";
}
onSubmit(e) {
 e.preventDefault();
 var sequence = this.state.v + 1;
 this.setState({v: sequence});
 if(this.state.clientText !== 'How can we help you?'){
  var post = {
    name: this.state.name,
    text: this.state.clientText,
    email: this.state.email,
    v: this.state.v
  }
}
if(this.state.clientText === 'How can we help you?') {
 var post = {
   name: this.state.name,
   text: '',
   email: this.state.email,
   v: this.state.v
 }}
this.props.login(post);
alert( "Success,\nWe will get back to your shortly");
}

  render() {
    return (
      <ScrollableAnchor id={'contact'}>
      <section id="form">
      <div id="input-form">
      <Translate content="contact.title" component="h1"/>
        <form onSubmit={this.onSubmit}>
          <div className="stylish">
            <input type="text" name="name" onFocus={this.focus} onBlur={ e => this.blur(e, this.state.firstInput)} onChange={this.onChange} value={this.state.name}/>
          </div>
          <div className="stylish">
            <input type="text" name="email" onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.secondInput)} onChange={this.onChange} value={this.state.email}/>
          </div>
          <div className="stylish">
            <textarea rows = "1" type="text" name="clientText" onFocus={this.focus} onBlur={ e => this.blur(e, this.state.thirdInput)} onChange={this.onChange} value={this.state.clientText}/>
          </div>
          <br />
          <button type="submit"><Translate content="contact.button"/></button>
        </form>
        <div id="SocialForm">
        <div className="SocialFormContent">

        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faMapMarkerAlt} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size="lg"/>
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.address" component="h3"/>
        <p>Ljubljana, SI</p></div>
        </div>
        <div className="SocialFormContent">
        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faPhoneAlt} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size='lg' />
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.phone" component="h3"/>
        <p>+386 51 621 161</p></div>
        </div>
        <div className="SocialFormContent">
        <div className="SocialFormIcon">
        <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faEnvelope} size="sm"/>
        <FontAwesomeIcon icon={faCircle} size='lg' />
        </span>
        </div>
        <div className="SocialFormText">
        <Translate content="contact.email" component="h3"/>
        <p>info@bprogramming.com</p></div>
        </div>
        </div>
        </div>
        <div className="map">
      <MapContainer />
      </div>
      <div id="interested">
      <div id="interestedTitle">
      <Translate content="contact.consultation" component="h2"/><br />
      <form onSubmit={this.onSubmit}>
      <div id="interestedInput">
      <input type="text" name="name" onFocus={this.focus} onBlur={ e => this.blur(e, this.state.firstInput)} onChange={this.onChange} value={this.state.name}/>
      <input type="text" name="email" onFocus={ e=> this.focus(e)} onBlur={ e => this.blur(e, this.state.secondInput)} onChange={this.onChange} value={this.state.email}/>
      <button type="submit"><Translate content="contact.button2"/></button>
      </div>
      </form>
      </div>
      </div>
        </section>
        </ScrollableAnchor>
    )
  }
}
postForm.propTypes = {
login: PropTypes.func.isRequired
};

export default connect(null, {login})(postForm);